body {
  margin: 0;
  overflow-x: hidden;
  /*font-family: 'FontspringNotDef';*/
  /*font-family: 'Raleway', sans-serif;*/
  /*font-family: 'Lato', sans-serif;*/
  font-family: 'Calibri', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
